<template>
  <c-box :w="['100%', '793px']">
    <c-flex w="100%" backgroundColor="white">
      <c-box
        :min-w="['76px', '152px']"
        :w="['76px', '152px']"
        :h="['76px', '152px']"
        :mr="['15px', '30px']"
        overflow="hidden"
        backgroundColor="lightGray.900"
      >
        <c-image
          object-fit="cover"
          :src="getPhotoUser(client.photoUrl)"
          :alt="client.firstName"
        />
      </c-box>
      <c-box w="100%">
        <c-heading
          as="h3"
          pos="relative"
          :mb="[null, '10px']"
          :fontSize="['24px', '36px']"
          :lineHeight="['36px', '54px']"
          fontWeight="700"
          color="black.900"
        >
          {{ client.firstName }} {{ client.lastName }}
        </c-heading>
        <c-text
          fontFamily="Roboto"
          :fontSize="['14px', '24px']"
          color="brand.900"
          :mb="['5px', '15px']"
        >
          {{ client.id }}
        </c-text>
        <c-box w="100%" display="flex" alignItems="center">
          <c-text
            fontFamily="Roboto"
            :fontSize="['12px', '18px']"
            color="gray.900"
            :mr="['10px', '20px']"
          >
            <c-image
              :src="require('@/assets/icon-phone.svg')"
              alt="icon"
              display="inline-block"
              marginRight="4px"
            />
            {{ client.phone }}
          </c-text>
          <c-text
            fontFamily="Roboto"
            :fontSize="['12px', '18px']"
            color="gray.900"
          >
            <c-image
              :src="require('@/assets/icon-mail.svg')"
              alt="icon"
              display="inline-block"
              marginRight="4px"
            />
            {{ client.email }}
          </c-text>
        </c-box>
      </c-box>
    </c-flex>
    <c-flex
      w="100%"
      backgroundColor="white"
      borderRadius="12px"
      border="1px solid #f2f2f2"
      box-shadow="0px 5px 30px 0px #0000000D"
      :px="['13px', '26px']"
      :py="['13px', '26px']"
      pos="relative"
      :my="['15px', '30px']"
    >
      <c-box width="64px" height="64px" :mr="['15px', '30px']">
        <c-flex
          justifyContent="center"
          alignItems="center"
          w="64px"
          h="64px"
          overflow="hidden"
          borderRadius="100%"
          backgroundColor="brand.900"
        >
          <c-image :src="require('@/assets/ic_meat_white.svg')" alt="icon" />
        </c-flex>
      </c-box>
      <c-box w="100%">
        <c-heading
          as="h3"
          marginBottom="12px"
          fontSize="18px"
          lineHeight="27px"
          fontWeight="700"
          color="black.900"
        >
          Meal Planner
        </c-heading>
        <c-text fontFamily="Roboto" fontSize="14px" color="darkGray.900">
          Last Update: {{ mealPlan.updatedAt | formatDate("DD MMMM YYYY") }}
        </c-text>
      </c-box>
      <c-button
        right-icon="chevron-right"
        fontSize="18px"
        fontWeight="500"
        padding="0"
        backgroundColor="transparent"
        color="brand.900"
        pos="absolute"
        top="50%"
        right="30px"
        transform="translateY(-50%)"
        as="router-link"
        :to="{ name: 'nutri.profile-gizi', params: $route.params }"
      >
        Edit
      </c-button>
    </c-flex>
    <c-box w="100%" marginBottom="15px" borderBottom="1px solid #F2F2F2">
      <c-heading
        fontFamily="Roboto"
        fontSize="18px"
        fontWeight="500"
        color="superDarkGray.900"
        marginTop="30px"
        marginBottom="8px"
      >
        History Kuesioner
      </c-heading>
    </c-box>
    <c-flex flexWrap="wrap" w="100%" gridGap="20px">
      <c-box v-for="program in programs" :key="program.id" w="386px">
        <c-flex
          w="100%"
          backgroundColor="white"
          borderRadius="12px"
          border="1px solid #f2f2f2"
          box-shadow="0px 5px 30px 0px #0000000D"
          px="26px"
          py="26px"
          pos="relative"
          margiBottom="30px"
        >
          <c-box w="100%">
            <c-heading
              as="h3"
              marginBottom="0px"
              fontSize="18px"
              lineHeight="27px"
              fontWeight="700"
              color="black.900"
            >
              {{ program.name | capitalize }}
            </c-heading>
            <c-text
              fontFamily="Roboto"
              fontWeight="500%"
              fontSize="18px"
              color="brand.900"
              marginBottom="10px"
            >
              {{ program.status | capitalize }}
            </c-text>
            <c-text fontFamily="Roboto" fontSize="14px" color="darkGray.900">
              {{ program.updatedAt | formatDate("DD MMMM YYYY") }}
            </c-text>
          </c-box>
          <c-button
            variant="ghost"
            min-w="0"
            w="32px"
            h="32px"
            p="10px"
            as="router-link"
            :to="{
              name: 'nutri.detail-quizionary',
              params: { programId: program.id },
            }"
          >
            <c-icon name="chevron-right" size="32px" color="#C4C4C4" />
          </c-button>
        </c-flex>
      </c-box>
    </c-flex>
  </c-box>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
import generalMixin from "@/utils/general-mixins";

export default {
  name: "DetailClient",
  mixins: [generalMixin],
  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
    client() {
      return this.$store.getters["clients/clientWithId"](this.clientId) ?? {};
    },
    mealPlan() {
      return this.client?.mealPlans?.[0] ?? {};
    },
    programs() {
      return this.client?.programs;
    },
  },
  methods: {
    ...mapActions({
      getClientById: "clients/getClientById",
    }),
  },
  mounted() {
    this.getClientById(this.clientId);
  },
  filters: {
    capitalize(value) {
      value = value.split("_").join(" ");
      value = _.capitalize(value);
      return value;
    },
  },
};
</script>

<style></style>
